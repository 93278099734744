<template>
    <div>
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>
                    <i class="el-icon-s-home"></i>
                    首页
                </el-breadcrumb-item>
                <el-breadcrumb-item>销售统计</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button type="success" style="height: 36px" @click="reload">
                <i style="font-size: 18px" class="el-icon-refresh-right"></i>
            </el-button>
        </div>
        <div style="display: flex; align-items: center; justify-content: flex-start; flex-wrap: wrap; padding: 20px 20px 0; white-space: nowrap">
            <el-row style="font-size: 14px">
                <el-col :span="10" style="display: flex; align-items: center; flex-wrap: nowrap">
                    <span>完成时间：</span>
                    <el-date-picker v-model="timeOpt" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-col>
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="10" style="display: flex; align-items: center; flex-wrap: nowrap">
                    <span>月份：</span>
                    <el-date-picker v-model="monthOpt" type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份"></el-date-picker>
                </el-col>
            </el-row>
            <el-row style="margin-top: 10px">
                <el-col :span="6">
                    <span>销售经理：</span>
                    <el-input v-model="QueryContent" placeholder="输入要查询的水站经理名称" style="width: 70%"></el-input>
                </el-col>
                <el-col :span="6">
                    <span>选择水站：</span>
                    <el-select v-model="option1" clearable filterable placeholder="选择水站" style="width: 70%">
                        <el-option v-for="item in option1es" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="6">
                    <span>选择商品：</span>
                    <el-select v-model="option2" clearable filterable placeholder="选择商品" style="width: 70%">
                        <el-option v-for="item in option2es" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="6">
                    <span>商品分类：</span>
                    <el-select v-model="option3" clearable filterable placeholder="商品分类">
                        <el-option v-for="item in option3es" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-col>
            </el-row>
        </div>
        <div style="padding: 10px 20px; border-bottom: 1px solid #e5e5e5; display: flex; align-items: center">
            <el-button type="success" size="mini">
                <div style="display: flex; align-items: center; font-size: 14px">
                    <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                    <span>查询</span>
                </div>
            </el-button>
            <el-button type="success" size="mini">
                <div style="display: flex; align-items: center; font-size: 14px">
                    <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                    <span>清楚</span>
                </div>
            </el-button>
            <el-button type="primary" size="mini">
                <div style="display: flex; align-items: center; font-size: 14px">
                    <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                    <span>导出报表</span>
                </div>
            </el-button>
        </div>
        <div style="margin: 20px;width: 95%;max-width: 100%;overflow-x: scroll;">
            <table class="table">
                <tr>
                    <th style="white-space: nowrap;" v-for="(item, index) in tableth" :key="index">{{ item }}</th>
                </tr>
                <tr v-for="(item, index) in tabletd" :key="index">
                    <td>{{ item.creatDate }}</td>
                    <td>{{ item.finishDate }}</td>
                    <td>{{ item.serial }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.driver }}</td>
                    <td>{{ item.courier }}</td>
                    <td>{{ item.product }}</td>
                    <td>{{ item.specification }}</td>
                    <td>{{ item.number }}</td>
                    <td>{{ item.purchasingCost }}</td>
                    <td>{{ item.money }}</td>
                    <td style="padding: 10px 20px; border: 0; border-bottom: 1px solid #d0cfd1; border-right: 1px solid #d0cfd1">
                        <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                        <span style="margin: 0 10px"></span>
                        <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                    </td>
                </tr>
                <tr v-if="this.tabletd.length == 0">
                    <td :colspan="20" style="color: #666">没有数据</td>
                </tr>
                <tr>
                    <td :colspan="2">合计</td>
                    <td v-for="item in 18" :key="item"></td>
                </tr>
            </table>
            <div class="tableBottom">
                <span v-if="isResult" style="">显示第&nbsp;1&nbsp;至&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>
                <span v-if="!isResult" style="">显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>
                <div style="display: flex; justify-content: flex-end; align-items: center">
                    <el-button>首页</el-button>
                    <el-button>上页</el-button>
                    <el-pagination background layout=" pager,slot" :total="1"></el-pagination>
                    <el-button>下页</el-button>
                    <el-button>末页</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            timeOpt: '',
            monthOpt: '',

            QueryContent: '',
            option1: '',
            option1es: [],
            option2: '',
            option2es: [],
            option3: '',
            option3es: [],

            tableth: ['水站名称', '商品名称', '05-01', '05-02', '05-03', '05-04', '05-05', '05-06', '05-07', '05-08', '05-09', '05-10', '05-11', '05-12', '05-13', '05-14', '05-15', '05-16', '05-17','合计'],
            tabletd: [],
            isResult: false,
            tableFinshNum: 0
        };
    },
    mounted() {
        if (this.tabletd.length == 0) {
            this.isResult = false;
        } else {
            this.isResult = true;
            this.tableFinshNum = this.tabletd.length;
        }
    },
    computed: {
        totalNum() {
            if (this.tabletd.length === 0) {
                return ' ';
            } else {
                return this.tabletd.reduce((acc, cur) => acc + cur.number, 0);
            }
        },
        totalCost() {
            if (this.tabletd.length === 0) {
                return ' ';
            } else {
                return this.tabletd.reduce((acc, cur) => acc + cur.purchasingCost, 0);
            }
        },
        totalMoney() {
            if (this.tabletd.length === 0) {
                return ' ';
            } else {
                return this.tabletd.reduce((acc, cur) => acc + cur.money, 0);
            }
        }
    },
    methods: {
        reload() {
            location.reload();
        },
        handleEdit() {},
        handleDelete() {}
    }
};
</script>
<style scoped lang="less">
.breadcrumb {
    height: 40px;
    line-height: 40px;
    box-sizing: content-box;
    border-bottom: 1px solid #e5e5e5;
    padding: 0 20px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tableBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #666;
    font-size: 14px;
}

.table {
    border-collapse: collapse;
    text-align: center;
    font-size: 14px;

    th {
        padding: 10px 20px;
        border: 1px solid #d0cfd1;
    }

    td {
        padding: 10px 20px;
        border: 1px solid #d0cfd1;
    }
}
</style>
